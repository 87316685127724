import React, { FC, useMemo, memo, useState, useEffect } from 'react';
import classNames from './RightSideComponent.module.css';
import ActionPanel from '../../ActionPanel/ActionPanel';
import IconButton from '../../../components/IconButton/IconButton';
import UnionModal from '../../UnionModal/UnionModal';
import useParticipants from '../../../hooks/audio/useParticipants';
import { devices } from '../../../types/devices';
import AvatarSettings from '../../../components/AvatarSettings/AvatarSettings';
import Notofi from '../../../assets/picture/Notifi.png';
import Modal from '../../../components/Modal/Modal';
import ModalHeader from '../../../components/Modal/ModalHeader/ModalHeader';
import Notification1 from '../../../assets/picture/Notification1.png';
import Report from '../../../assets/picture/report.png';

export interface RightSideComponentProps {
  onUnionClick?: () => void;
  roomId: string;
  onCloseUnionModal: () => void;
  onThrowStuff: () => void;
  onToggleCamera?: () => void;
  isCameraClick: boolean;
  isOpen: boolean;
  isAdmin: boolean;
  isConference: boolean;
  onToggleNight: () => void;
  device: string;
}

const RightSideComponent: FC<RightSideComponentProps> = memo((props) => {
  const {
    onUnionClick = () => undefined,
    onCloseUnionModal = () => undefined,
    onThrowStuff = () => undefined,
    isCameraClick,
    onToggleCamera = () => undefined,
    onToggleNight = () => undefined,
    roomId,
    isOpen = false,
    isConference = false,
    isAdmin,
    device,
  } = props;

  const root = useMemo(() => classNames[`root_${device}`], [device]);
  const [isOpenNot, setIsOpen] = useState<boolean>(false);
  const [isQuestionOpen, setIsQuestionOpen] = useState<boolean>(false);
  const { participants } = useParticipants();

  useEffect(() => {
    if (device === devices.mobile) setIsOpen(false);
  }, [device, isOpenNot]);

  return (
    <div>
      <div className={root}>
        <ActionPanel
          direction="row"
        >
          {device === devices.desktop && <IconButton
            size={device === devices.mobile ? 'Xs' : 'Md'}
            disabled={!isConference}
            icon="sun"
            onCLick={onToggleNight}
            isActive={false}
          />}
          <IconButton
            size={device === devices.mobile ? 'Xs' : 'Md'}
            disabled={!isConference}
            icon="camera"
            isActive={isCameraClick}
            onCLick={onToggleCamera}
          />
          <IconButton
            size={device === devices.mobile ? 'Xs' : 'Md'}
            disabled={!isConference}
            isActive={isOpen}
            onCLick={onUnionClick}
            icon="union"
          />
          {device === devices.desktop &&<IconButton
            size={device === devices.mobile ? 'Xs' : 'Md'}
            disabled={!isConference}
            isActive={isOpen}
            onCLick={() => setIsQuestionOpen(true)}
            icon="question"
          />}
        </ActionPanel>
        <UnionModal
          isAdmin={isAdmin}
          roomId={roomId}
          participants={participants}
          onClose={onCloseUnionModal}
          isOpen={isOpen}
        />
      </div>
      <div style={{ position: 'absolute', top: 20, right: 20, cursor: 'pointer' }}>
        {device === devices.desktop && <div style={{ display: 'flex', alignItems: 'center', columnGap: 5 }}>
          <div>
            <img
              onClick={() => setIsOpen(true)}
              src={Notofi}
            />
          </div>
          <AvatarSettings />
        </div>}
      </div>
      <Modal
        isOpen={isOpenNot}
        position={{
          top: '95px',
          right: '110px',
          bottom: 'auto',
          left: 'auto',
        }}
      >
        <ModalHeader
          title="Your Notifications"
          onClose={() => setIsOpen(false)}
        />
        <img
          style={{ padding: 15 }}
          src={Notification1}
        />
      </Modal>
      <Modal
        isOpen={isQuestionOpen}
        position={{
          top: 'auto',
          right: '40px',
          bottom: '100px',
          left: 'auto',
        }}
      >
        <ModalHeader
          title="Your Notifications"
          onClose={() => setIsQuestionOpen(false)}
        />
        <img
          style={{ padding: 15 }}
          src={Report}
        />
      </Modal>
    </div>
  );
});

export default RightSideComponent;

import {
  ChangeEvent, FC, useCallback, useContext, useMemo, useState,
} from 'react';
import { Participant } from '@voxeet/voxeet-web-sdk/types/models/Participant';
import Modal from '../../components/Modal/Modal';
import ModalHeader from '../../components/Modal/ModalHeader/ModalHeader';
import classNames from './UnionModal.module.css';
import { AudioContext } from '../../context/AudioProvider/AudioProvider';
import UserList from './UserList';
import Input from '../../components/Input/Input';
import InviteRoom from './InviteRoom';
import useParticipants from '../../hooks/audio/useParticipants';
import useMediaQuery from '../../hooks/useMediaQuery';
import { devices } from '../../types/devices';
import {useWindowSize} from "react-use";

export interface ChatModalProps {
  onClose: () => void;
  isOpen: boolean;
  roomId: string;
  isAdmin: boolean;
  participants: Participant[];
}

const UnionModal: FC<ChatModalProps> = (props) => {
  const {
    roomId,
    onClose = () => undefined,
    isOpen = false,
    isAdmin,
    participants,
  } = props;

  const [searchParticipants, setSearchParticipants] = useState<Participant[]>();

  const { device } = useMediaQuery();

  const location = useMemo(() => {
    return `${window.location.origin}/?room=${roomId}`;
  }, [roomId]);

  const { kickParticipantHandler } = useParticipants();

  const changeValueHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    const filter = participants.filter((participant) => participant.info.name?.toLowerCase().includes(value));
    setSearchParticipants(filter);
  }, [participants]);

  const { width } = useWindowSize();
  const { participantsStatus, addIsSpeakingListener } = useContext(AudioContext);

  const positionMobile = useMemo(() => {
    return width < 414 ? '-122px' : '-125px';
  }, [width]);

  const widthModal = useMemo(() => {
    return width < 405 ? '340px' : '394px';
  }, [width]);


  return (
    <Modal
      height={device === devices.desktop ? '827px' : '100vh'}
      width={device === devices.desktop ? '410px' : widthModal}
      isOpen={isOpen}
      position={{
        bottom: device === devices.desktop ? '16px' : '-64px',
        left: 'auto',
        right: device === devices.desktop ? '-145px' : positionMobile,
        top: 'auto',
      }}
    >
      <ModalHeader
        title="Users list"
        onClose={onClose}
      />
      <div className={classNames.divider} />
      {device === devices.desktop && <div className={classNames.input}>
        <Input
          width="362px"
          placeholder="Search"
          onChange={changeValueHandler}
        />
      </div>}
      <UserList
        addIsSpeakingListener={addIsSpeakingListener}
        isAdmin={isAdmin}
        onKickParticipant={kickParticipantHandler}
        participantsStatus={participantsStatus}
        participants={searchParticipants || participants}
      />
      <div className={classNames.divider} />
      <InviteRoom link={location} />
    </Modal>
  );
};

export default UnionModal;
